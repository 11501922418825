
import * as prismicRuntime$eeBMIuisiI from '/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxt+image@1.5.0_rollup@4.14.1/node_modules/@nuxt/image/dist/runtime/providers/prismic'
import * as ipxRuntime$XTMhgPeLXG from '/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxt+image@1.5.0_rollup@4.14.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['prismic']: { provider: prismicRuntime$eeBMIuisiI, defaults: {} },
  ['ipx']: { provider: ipxRuntime$XTMhgPeLXG, defaults: {} }
}
        